<template>
  <div class="edit-spa-due-date">
    <fd-form @submit="$emit('save', form)">
      <modal-content class="card">
        <template #header>
          <h4 class="p-3">Edit SPA Due Date</h4>
        </template>
        <template #body>
          <div class="p-3">
            <fd-date-picker
              v-model="form.spaDueDate"
              :min="$moment(dueDate).add(1, 'days')"
              label="New SPA Due Date"
              :validators="[validator.required]"
            ></fd-date-picker>

            <div class="d-flex justify-content-end mt-3">
              <fd-button type="submit" class="main">Save</fd-button>
            </div>
          </div>
        </template>
      </modal-content>
    </fd-form>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";
import ModalContent from "@/components/GlobalComponents/ModalBox/ModalContent";

export default {
  components: {
    ModalContent
  },
  mixins: [],
  props: {
    dueDate: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      form: {
        spaDueDate: ""
      },
      validator: {
        required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
